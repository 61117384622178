@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap');

.App {
  font-family: 'Nunito', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.customModal {
  font-family: 'Nunito', sans-serif;
}

.logo {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  margin-left: 16px;
}